$gray-color: #e5e5e5;
$gray-color-1: #a7b3bf;
$gray-color-2: #f0f1f5;
$gray-color-3: #f9f9fb;
$purple-color: #7d17d4;
$purple-color-2: #501388;
$purple-color-3: #951cb0;
$orange-color: #f4921b;
$red-color: #ff0083;
$red-color-2: #ff0000;

$oriange-bg: linear-gradient(95.4deg, #ff991e 8.46%, #f4921b 71.41%);
$oriange-bg-2: linear-gradient(121.65deg, #ff991e 14.3%, #f4921b 80.45%);
$purple-bg: linear-gradient(
  90deg,
  #6706ba 48.52%,
  #6f0ac6 72.11%,
  #811bd9 100%
);
