// navbar style
.navbar-content {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .navbar-menu {
    display: flex;
    align-items: center;
    .menu-item {
      letter-spacing: 0.02em;
      color: $purple-color;
    }
    .menu-item.btn {
      margin-left: 20px;
      color: white;
      background: $oriange-bg;
      border-radius: 9px;
      cursor: pointer;
    }
  }
}

.address-modal {
  .header {
    padding-bottom: 10px !important;
  }
  .account {
    &.active,
    &:hover {
      background: $oriange-bg-2;
      .content,
      .account-name,
      .account-address {
        color: white !important;
      }
    }
  }
}

// home page style
.home-page {
  padding-bottom: 30px;
  background-color: $gray-color;
  min-height: 100vh;

  .home-content {
    .content-item {
      .title {
        font-family: Ubuntu Mono;
        font-weight: bold;
        letter-spacing: 0.02em;
      }
      .form-input {
        &.amount input {
          padding-right: 28%;
        }
        input {
          &:focus {
            outline-width: 0;
            border-color: $purple-color-2;
          }
          box-shadow: inset 0px 4px 7px rgba(0, 0, 0, 0.11);
          border-radius: 9px;
          color: white;
          font-size: 22px;
          font-weight: bold;
          background-color: $purple-color-2;
        }
        .max-btn {
          background: $oriange-bg-2;
        }
      }
      .reward {
        .result {
          background: $red-color;
          box-shadow: 0px 4px 14px rgba(255, 0, 131, 0.45);
          border-radius: 0px 0px 9px 9px;
        }
      }
      &.contribute {
        border-radius: 13px;
        box-shadow: 0px 0px 35px rgba(125, 23, 212, 0.65);
        background: $purple-bg;
        color: white;
      }
      &.details {
        border-radius: 13px;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.07);
        .title {
          color: $purple-color-2;
        }
        .history {
          min-height: 182px;
          .arrow-down,
          .arrow-up {
            bottom: 0;
            border: 2px solid $purple-color-2;
            right: -15px;
          }
        }
      }
      &.crowdloan {
        .item {
          border-radius: 13px;
          box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.07);
        }
        .title {
          color: $purple-color-2;
        }
        .graph-line canvas {
          padding-top: 20px;
        }
      }
    }
  }
  .contributions-details {
    .title {
      font-family: Ubuntu Mono;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: $purple-color-2;
    }
  }
  .min-w-table {
    min-width: 1200px;
  }
  .min-w-table-md {
    min-width: 350px;
  }
}

@media screen and (min-width: 1500px) {
  .home-page {
    .content-item .total-value {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-page {
    .home-content {
      .item {
        width: 100% !important;
      }
      .ui.grid > .row {
        flex-direction: column;
      }
      .content-item {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .navbar-content {
    .navbar-menu .menu-item.btn {
      width: 120px;
      margin-left: 10px;
      text-align: center;
    }
  }
}
