@import './colors.scss';

.calamari-text {
  color: $purple-color-2;
}
.text-gray {
  color: $gray-color-1;
}
.purple-text {
  color: $purple-color-3;
}
.oriange-text {
  color: $orange-color;
}
.bg-gray {
  background-color: $gray-color-2;
}
.bg-purple {
  background-color: $purple-color-3;
}
.bg-light-gray {
  background-color: $gray-color-3;
}
.bg-red {
  background-color: $red-color-2;
}
.bg-oriange {
  background: $oriange-bg;
}
.border-2 {
  border: 2px solid $purple-color-2;
}
